'use client';

import { Breakpoint, useTheme, useMediaQuery } from '@mui/material';
export const useIsDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('md'));
};
export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};
export const useIsUnderBreakpoint = (breakpointKey: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpointKey));
};
export const useIsOverBreakpoint = (breakpointKey: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpointKey));
};