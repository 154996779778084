import { useInterval } from 'react-use';

const INTERVAL = 30 * 60 * 1000; // in ms

const BUILD_ID_ON_CLIENT = process.env.BUILD_ID;

export function useDeployRefreshManager() {
  useInterval(async () => {
    if (typeof window === 'undefined') return;

    try {
      const response = await fetch('/api/build-id', { cache: 'no-store' });
      const { buildId: buildIdOnServer } = await response.json();

      if (
        buildIdOnServer &&
        BUILD_ID_ON_CLIENT &&
        buildIdOnServer !== BUILD_ID_ON_CLIENT
      ) {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  }, INTERVAL);
}
