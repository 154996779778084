import { ReactNode } from 'react';
import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Drawer, Grid, Stack, SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { Widget } from '@/shared/Constants';
const getExtraPaddingForTheWidget = (isWidgetHorizontal: boolean, isMobile: boolean) => {
  /**
   * Demo apps only (for the vertical widget)
   */
  if (!isWidgetHorizontal) {
    if (isMobile) {
      return {
        pt: 0,
        pb: Widget.VERTICAL_STYLE_NAVBAR_HEIGHT,
        pr: 0
      };
    } else {
      return {
        pt: 0,
        pb: 0,
        pr: Widget.VERTICAL_STYLE_NAVBAR_WIDTH
      };
    }
  }

  /**
   * All other apps (for the horizontal widget)
   */
  if (isMobile) {
    return {
      pt: 0,
      pb: Widget.HORIZONTAL_STYLE_NAVBAR_MOBILE_HEIGHT,
      pr: 0
    };
  } else {
    return {
      pt: Widget.HORIZONTAL_STYLE_NAVBAR_DESKTOP_HEIGHT,
      pb: 0,
      pr: 0
    };
  }
};
type Props = {
  children: ReactNode;
  isWidgetHorizontal: boolean;
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  drawerPaperSx?: SystemStyleObject;
  gridContainerSx?: SxProps;
};
export const RightDrawer = ({
  children,
  isWidgetHorizontal,
  isOpen,
  onClose,
  onBack,
  drawerPaperSx = {},
  gridContainerSx = {}
}: Props) => {
  const isMobile = useIsMobile();
  const widgetPadding = getExtraPaddingForTheWidget(isWidgetHorizontal, isMobile);
  const backIcon = <IconButton aria-label="back" sx={{
    position: 'absolute',
    top: 16,
    left: 16
  }} onClick={onBack}>
      <ArrowBackIcon />
    </IconButton>;
  const closeIcon = <IconButton aria-label="close" onClick={onClose} sx={{
    position: 'absolute',
    top: 16,
    right: 16 + widgetPadding.pr,
    zIndex: 2
  }}>
      <Close />
    </IconButton>;
  return <Drawer transitionDuration={0} anchor="right" open={isOpen} onClose={onClose} PaperProps={{
    elevation: 0
  }} sx={{
    '& .MuiDrawer-paper': {
      width: [400, 600, 800, 1000],
      ...drawerPaperSx
    }
  }} data-sentry-element="Drawer" data-sentry-component="RightDrawer" data-sentry-source-file="RightDrawer.tsx">
      <Stack width="100%" height="100%" sx={{
      pr: `${widgetPadding.pr}px`,
      pt: `${widgetPadding.pt}px`,
      pb: `${widgetPadding.pb}px`
    }} data-sentry-element="Stack" data-sentry-source-file="RightDrawer.tsx">
        {onBack && backIcon}
        {closeIcon}
        <Grid container direction="row" sx={{
        overflowY: 'auto',
        maxHeight: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        py: [2, 3],
        px: [2, 3],
        ...gridContainerSx
      }} data-sentry-element="Grid" data-sentry-source-file="RightDrawer.tsx">
          {children}
        </Grid>
      </Stack>
    </Drawer>;
};