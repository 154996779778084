import { createContext, useContext, useState, ReactNode, SetStateAction, Dispatch } from 'react';
import { UserInfo } from '@/shared/types/controllers/AuthControllerTypes';
import { PartPublicQtyAndPriceResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
export type PriceAndOriginalPrice = Pick<PartPublicQtyAndPriceResponse, 'originalPrice' | 'price'>;
type PriceAndOriginalPriceRequest = {
  status: 'success' | 'queued' | 'pending';
  data?: PriceAndOriginalPrice;
};
export type PartsMap = Record<number, PriceAndOriginalPriceRequest>;

/**
 * `UserInfo` when user is authenticated. `null` when user is not authenticated. `undefined` when user is loading.
 */
type AuthUserType = UserInfo | null | undefined;
type WidgetPartsPricesContextType = {
  parts: PartsMap;
  setParts: Dispatch<SetStateAction<PartsMap>>;
  authUser: AuthUserType;
  setAuthUser: Dispatch<SetStateAction<AuthUserType>>;
  isIframeLoaded: boolean;
  setIsIframeLoaded: Dispatch<SetStateAction<boolean>>;
};
const WidgetPartsPricesContext = createContext<WidgetPartsPricesContextType | undefined>(undefined);
export const useWidgetPartsPrices = () => {
  const context = useContext(WidgetPartsPricesContext);
  if (!context) {
    throw new Error('useWidgetPartsPrices must be used within a WidgetPartsPricesProvider');
  }
  return context;
};
export const WidgetPartsPricesProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [parts, setParts] = useState<PartsMap>({});
  const [authUser, setAuthUser] = useState<AuthUserType>();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  return <WidgetPartsPricesContext.Provider value={{
    parts,
    setParts,
    authUser,
    setAuthUser,
    isIframeLoaded,
    setIsIframeLoaded
  }} data-sentry-element="unknown" data-sentry-component="WidgetPartsPricesProvider" data-sentry-source-file="WidgetPartsPricesProvider.tsx">
      {children}
    </WidgetPartsPricesContext.Provider>;
};